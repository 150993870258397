import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id: number;
}

export interface ApiResult{
  id: number,
  customer_id: number,
  firstname: string,
  lastname: string,
  company: string,
  customer_firstname: string,
  customer_lastname: string,
  address1: string,
  address2: string,
  nip: string,
  alias: string,
  postcode: string,
  city: string,
  country_id: number,
  other: string,
  phone: string,
  phone_mobile: string,
  vat_number: string,
  created_at: string,
  updated_at: string,
  is_active: boolean,
  is_delete: boolean
}

export class ApiAdminAddressDetails extends _rootApi{
    static request(params:ApiParams, func_success?: (r:ApiResult) => void, func_error?: () => void):void{
        this._request({
            url: '/address/details',
            params: params as unknown as {[index:string]:string},
            func_success: (r) => {
                if(func_success) func_success(r);
            },
            func_error: () => {
                if(func_error) func_error();
            }
        });
    }
}