import { Account } from "../Account";
import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiResult{
    firstname: string,
    lastname: string,
    email: string
}

export class ApiAdminAccount extends _rootApi{
    static request(func_success?: () => void, func_error?: () => void):void{
        this._request({
            url: '/account',
            func_success: (r:ApiResult) => {
                Account.login(r);
                if(func_success) func_success();
            },
            func_error: () => {
                Account.logout();
                if(func_error) func_error();
            }
        });
    }
}