import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id: number;
}

export interface ApiResult{
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  birthday: string|null;
  isactive: boolean;
  newsletter: boolean;
  dt: string;
}

export class ApiAdminCustomerDetails extends _rootApi{
    static request(params:ApiParams, func_success?: (r:ApiResult) => void, func_error?: () => void):void{
        this._request({
            url: '/customer/details',
            params: params as unknown as {[index:string]:string},
            func_success: (r) => {
                if(func_success) func_success(r);
            },
            func_error: () => {
                if(func_error) func_error();
            }
        });
    }
}