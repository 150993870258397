import { ApiResult } from "./Api/Account";

export enum Lang{pl,en}
export enum Currency{pln,eur,usd}

export class Account{
    static info:ApiResult = { email: '', firstname: '', lastname: ''}; 
    static get is_logged_in():boolean{ return this._auth_status; }
    
    private static _auth_status = false;
    private static auth_queue:{[index:string]:(info:ApiResult) => void} = {};

    static init():void{
        const doc = document as unknown as { account:ApiResult | null };

        if(doc.account){
            this.login(doc.account);
        }
    }

    static login(info:ApiResult):void{
        this._auth_status = true;
        this.reload_info(info);

        this.auth_queue_run();
    }

    static logout():void{
        this._auth_status = false;
        this.info.email = '';
        this.info.firstname = '';
        this.info.lastname = '';

        this.auth_queue_run();
    }

    static auth_queue_add(key:string, func:(info:ApiResult) => void):void{
        this.auth_queue[key] = func;

        if(this.is_logged_in){
            func(this.info);
        }
    }

    static auth_queue_del(key:string):void{
        if(typeof(this.auth_queue[key]) !== 'undefined'){
            delete this.auth_queue[key];
        }
    }

    private static auth_queue_run():void{
        Object.values(this.auth_queue).forEach(v => v(this.info));
    }

    private static reload_info(info:ApiResult):void{
        this.info['email'] = info['email'];
        this.info['firstname'] = info['firstname'];
        this.info['lastname'] = info['lastname'];
    }

}