import { ApiAdminGeneralCountries } from "../Api/GeneralCountries";

export class GeneralTemp{
  executed = false;
  completed = false;
  data = {};
  func_queue:Array<(v:any) => void> = [];

  add_func_to_queue(func){
      if(this.executed && !this.completed){
          this.func_queue.push((func as (v:any) => void));
      }
      else if(this.executed && this.completed){
          func(this.data);
      }
  }

  run_func_queue(){
      this.func_queue.forEach((func) => {
          (func as (v:any) => void)(this.data);
      });
      this.completed = true;
      this.func_queue = [];
  }

  run_with_data(func, req:(params:{lang_id:number}, func_success?: (r:any) => void, func_error?: (r:any) => void, func_finally?: () => void) => void){
      if(this.executed){
          this.add_func_to_queue(func);
          return;
      }

      this.executed = true;
      ApiAdminGeneralCountries.request({lang_id: 1},
        (r) => this.data = r,
        () => this.data = {},
        () => {
          func(this.data);
          this.run_func_queue();
        }
      );
  }
}