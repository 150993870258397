import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id: number;
  email?: string;
  password?: string;
  firstname?: string;
  lastname?: string;
  birthday?: string;
  isactive?: boolean;
  newsletter?: boolean;
}

export interface ApiResult{
    result?: string,
    errors?: {[index:string]:Array<string>},
    message?: string
}

export class ApiAdminCustomerUpdate extends _rootApi{
    static request(params:ApiParams, func_success?: (r:ApiResult) => void, func_error?: (r:ApiResult) => void):void{
        this._request({
            url: '/customer/update',
            params: params as unknown as {[index:string]:string},
            func_success: (r) => {
                if(func_success) func_success(r);
            },
            func_error: (r) => {
                if(func_error) func_error(r);
            }
        });
    }
}