import { ApiAdminGeneralCountries, ApiResultCorrect } from "../Api/GeneralCountries";
import { GeneralTemp } from "./_temp";

export class GeneralCountries{
  private static general_temp:GeneralTemp|null = null;

  static run_with_data(func:(data:ApiResultCorrect) => void){
    if(!this.general_temp){
      this.general_temp = new GeneralTemp();
    }
    const g = this.general_temp as GeneralTemp;

    if(g.executed){
        g.add_func_to_queue(func);
        return;
    }

    g.executed = true;
    ApiAdminGeneralCountries.request({lang_id: 1},
      r => g.data = r, () => g.data = {},
      () => {
        func(g.data);
        g?.run_func_queue();
      }
    );
  }

  static run_with_data_sorted_alphabetically(func:(data:{[index:string]:number}) => void){
    this.run_with_data((data) => {
      // Zamiana kluczy i wartości
      let flippedData: {[index:string]:string} = {};
      for (let key in data) {
          flippedData[data[key]] = key;
      }

      // Sortowanie kluczy
      let sortedKeys = Object.keys(flippedData).sort();

      // Tworzenie nowego obiektu z posortowanymi kluczami
      let sortedData: {[index:string]:number} = {};
      for (let key of sortedKeys) {
          sortedData[key] = Number(flippedData[key]);
      }

      func(sortedData);
    });
  }
}