import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import PanelHome from '../views/Panel/Home.vue';
import PanelSellCustomers from '../views/Panel/Sell/Customers.vue';
import PanelSellCustomersNew from '../views/Panel/Sell/Customers/New.vue';
import PanelSellCustomersEdit from '../views/Panel/Sell/Customers/Edit.vue';
import PanelSellCustomersDetails from '../views/Panel/Sell/Customers/Details.vue';
import PanelSellAddresses from '../views/Panel/Sell/Addresses.vue';
import PanelSellAddressesNew from '../views/Panel/Sell/Addresses/New.vue';
import PanelSellAddressesEdit from '../views/Panel/Sell/Addresses/Edit.vue';
import PanelSellAddressesDetails from '../views/Panel/Sell/Addresses/Details.vue';
import PanelSellOrders from '../views/Panel/Sell/Orders.vue';
import PanelSellOrdersNew from '../views/Panel/Sell/Orders/New.vue';
import PanelSellOrdersEdit from '../views/Panel/Sell/Orders/Edit.vue';
import PanelSellOrdersDetails from '../views/Panel/Sell/Orders/Details.vue';

const routes: Array<RouteRecordRaw> = [
    { path: '/', name: 'Login', component: Login },
    { path: '/panel', name: 'PanelHome', component: PanelHome },
    { path: '/panel/sell/customers', name: 'PanelSellCustomers', component: PanelSellCustomers },
    { path: '/panel/sell/customers/new', name: 'PanelSellCustomersNew', component: PanelSellCustomersNew },
    { path: '/panel/sell/customers/:customer_id/edit', name: 'PanelSellCustomersEdit', component: PanelSellCustomersEdit },
    { path: '/panel/sell/customers/:customer_id', name: 'PanelSellCustomersDetails', component: PanelSellCustomersDetails },
    { path: '/panel/sell/addresses', name: 'PanelSellAddresses', component: PanelSellAddresses },
    { path: '/panel/sell/addresses/new', name: 'PanelSellAddressesNew', component: PanelSellAddressesNew },
    { path: '/panel/sell/addresses/:address_id/edit', name: 'PanelSellAddressesEdit', component: PanelSellAddressesEdit },
    { path: '/panel/sell/addresses/:address_id', name: 'PanelSellAddressesDetails', component: PanelSellAddressesDetails },
    { path: '/panel/sell/orders', name: 'PanelSellOrders', component: PanelSellOrders },
    { path: '/panel/sell/orders/new', name: 'PanelSellOrdersNew', component: PanelSellOrdersNew },
    { path: '/panel/sell/orders/:order_id/edit', name: 'PanelSellOrdersEdit', component: PanelSellOrdersEdit },
    { path: '/panel/sell/orders/:order_id', name: 'PanelSellOrdersDetails', component: PanelSellOrdersDetails },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;