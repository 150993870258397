import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiResult {
  conversion_rate: number,
  abandoned_carts: number,
  average_order_value: number,
  net_profit_per_visit: number
}

export class ApiAdminOrdersGeneral extends _rootApi{
  static request(func_success?: (v:ApiResult) => void, func_error?: () => void):void{
    this._request({
      url: '/orders/general',
      func_success: (r:ApiResult) => {
        if(func_success) func_success(r);
      },
      func_error: () => {
        if(func_error) func_error();
      }
    });
  }
}