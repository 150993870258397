import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id: number,
  company?: string,
  firstname?: string,
  lastname?: string,
  address1?: string,
  address2?: string,
  nip?: string,
  alias?: string,
  postcode?: string,
  city?: string,
  country_id?: number,
  other?: string,
  phone?: string,
  phone_mobile?: string,
  vat_number?: string,
  is_active?: boolean
  is_delete?: boolean
}

export interface ApiResult{
    result?: string,
    errors?: {[index:string]:Array<string>},
    message?: string
}

export class ApiAdminAddressUpdate extends _rootApi{
    static request(params:ApiParams, func_success?: (r:ApiResult) => void, func_error?: (r:ApiResult) => void):void{
        this._request({
            url: '/address/update',
            params: params as unknown as {[index:string]:string},
            func_success: (r) => {
                if(func_success) func_success(r);
            },
            func_error: (r) => {
                if(func_error) func_error(r);
            }
        });
    }
}