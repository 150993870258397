import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id?: number;
  firstname?: string;
  lastname?: string;
  email?: string;
  isactive?: boolean;
  newsletter?: boolean;
  dt_from?: string,
  dt_to?: string,
  sort_by?: ('id' | 'email' | 'firstname' | 'lastname' | 'isactive' | 'newsletter' | 'dt') | string,
  sort_order?: 'asc' | 'desc',
  page?: number,
  per_page?: number;
}

export interface ApiResult{
  data: Array<ApiResultDataSingle>,
  data_count: number
}

export interface ApiResultDataSingle{
  id: number,
  firstname: string,
  lastname: string,
  email: string,
  total_spent: number,
  isactive: boolean,
  newsletter: boolean,
  dt: string
}

export class ApiAdminCustomersSearch extends _rootApi{
    static request(params:ApiParams, func_success?: (v:ApiResult) => void, func_error?: () => void):void{
        this._request({
            url: '/customers/search',
            params: params as unknown as {[index:string]:string},
            func_success: (r:ApiResult) => {
                if(func_success) func_success(r);
            },
            func_error: () => {
                if(func_error) func_error();
            }
        });
    }
}