import axios from "axios";

export interface ApiBaseProps{
    params?: {[index:string]:string|number|boolean},
    url: string,
    method?: string,
    response_type?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream',
    func_success?: (data:any) => void,
    func_error?: (data:any) => void,
    func_finally?: () => void
}

export class _rootApi{
    static redirect_logout:() => void = () => null;

    static _request(req:ApiBaseProps):void{
        // response functions
        const func_success:(data) => void = (data) => {
            if(req.func_success){ req.func_success(data); }
        };
        const func_error:(data) => void = (data) => {
            console.log("error");
            if(req.func_error){ req.func_error(data); }
        };
        const func_finally:() => void = () => {
            if(req.func_finally){ req.func_finally(); }
        };

        // request
        axios.request({
            method: req.method ?? 'post',
            url: '/api' + req.url,
            data: req.params,
            responseType: (req.response_type ?? 'json')
        }).then((d) => {
            if(d.status == 200){
                func_success(d.data);
            }
            else{
                func_error(d.data);

                if(d.status === 401){
                    this.redirect_logout();
                }
            }
        })
        .catch((data) => { func_error(data.response?.data); })
        .finally(() => { func_finally(); });
    }
}