import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiResult{
  customers_count: number,
  average_age: number,
  orders_count: number,
  newsletter_count: number
}

export class ApiAdminCustomersGeneral extends _rootApi{
    static request(func_success?: (v:ApiResult) => void, func_error?: () => void):void{
        this._request({
            url: '/customers/general',
            func_success: (r:ApiResult) => {
                if(func_success) func_success(r);
            },
            func_error: () => {
                if(func_error) func_error();
            }
        });
    }
}