import { Global } from "./Global";

interface Response{
    errors?: {[index:string]:Array<string>}
}

export enum UpdateType{
    none,
    new,
    edit,
    delete
}

export class Form{
    public static last_update_type:UpdateType = UpdateType.none;
    private static tag(name:string):HTMLInputElement|undefined{
        return (document.querySelector('[name=' + name + ']') as HTMLInputElement|undefined);
    }
    private static tag_error(tag:HTMLInputElement|undefined):HTMLDivElement|undefined{
        if(tag){
          if(Global.is_web){
            return tag.nextSibling as HTMLDivElement | undefined;
          }
          else{
            const next_tag = tag.parentElement;
            // find child tag with class 'error'
            for(let i = 0; i < Number(next_tag?.children.length); i++){
              if(next_tag?.children[i].classList.contains('error')){
                return next_tag?.children[i] as HTMLDivElement;
              }
            }
            return next_tag?.nextSibling as HTMLDivElement | undefined;
          }
        }

        return undefined;
    }

    static value(name:string):string{
        const tag = this.tag(name) as HTMLInputElement|undefined;
        if(tag?.type === 'select'){
            return (tag as unknown as HTMLSelectElement).selectedOptions[0].value;
        }

        return tag?.value ?? '';
    }

    static value_checked(name:string):boolean{
        const tag = this.tag(name) as HTMLInputElement|undefined;
        if(tag?.type === 'checkbox'){
            return tag?.checked;
        }

        return false;
    }

    static value_set(name:string, value:string):void{
        const tag = this.tag(name);
        if(tag){
            tag.value = value;
        }
    }

    private static errors: Array<string> = [];
    static error_clear(name:string):void{
        const tag = this.tag(name);
        const tag_error = this.tag_error(tag);

        if(tag){ tag.setAttribute('class', Global.is_web ? '' : 'form-control'); }
        if(tag_error){ tag_error.innerText = ''; }
    }
    static error_set(name:string, error:string):void{
        const tag = this.tag(name);
        const tag_error = this.tag_error(tag);

        if(tag){ tag.setAttribute('class', 'form-control is-invalid'); }
        if(tag_error){ tag_error.innerText = error; }
    }
    static error_reflesh(response: Response = {}):void{
        // clear errors
        const bef = (this.errors.filter(v => !response.errors || !Object.keys(response.errors).includes(v)));
        bef.forEach(name => {
            this.error_clear(name);
        });

        // save errors
        if(response.errors && Object.keys(response.errors).length > 0){
            this.errors = Object.keys(response.errors);
        }
        else{
            this.errors = [];
        }

        // set errors
        this.errors.forEach(name => {
            this.error_set(name, response.errors ? response.errors[name][0] : '');
        });
    }
}