<template>
  <div class="modal fade" id="modalAddPriceDown" tabindex="-1" role="dialog" aria-labelledby="modalAddPriceDownLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalAddPriceDownLabel">Dodaj rabat (TODO)</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="name">Nazwa</label>
            <input type="text" class="form-control" id="name">
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="type">Typ</label>
              <select class="form-control" id="type">
                <!-- Opcje dla selecta -->
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="value">Wartość</label>
              <input type="text" class="form-control" id="value">
            </div>
          </div>
          <div class="form-group">
            <label for="invoice">Faktura</label>
            <select class="form-control" id="invoice">
              <!-- Opcje dla selecta -->
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Anuluj</button>
          <button type="button" class="btn btn-primary">Dodaj</button>
        </div>
      </div>
    </div>
  </div>
</template>