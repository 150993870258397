import { ApiAdminGeneralCurrencies, ApiResultCorrect } from "../Api/GeneralCurrencies";
import { GeneralTemp } from "./_temp";

export class GeneralCurrencies{
  private static general_temp:GeneralTemp|null = null;

  static run_with_data(func:(data:ApiResultCorrect) => void){
    if(!this.general_temp){
      this.general_temp = new GeneralTemp();
    }
    const g = this.general_temp as GeneralTemp;

    if(g.executed){
        g.add_func_to_queue(func);
        return;
    }

    g.executed = true;
    ApiAdminGeneralCurrencies.request({lang_id: 1},
      r => g.data = r, () => g.data = {},
      () => {
        func(g.data);
        g?.run_func_queue();
      }
    );
  }
}