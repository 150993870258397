import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id?: number;
  reference?: string;
  newcustomer?: boolean;
  delivery?: string;
  customer?: string;
  total?: number;
  payment?: string;
  status?: string;
  date?: string;
  sort_by?: ('id' | 'reference' | 'newcustomer' | 'delivery' | 'customer' | 'total' | 'payment' | 'status' | 'date') | string,
  sort_order?: 'asc' | 'desc',
  page?: number,
  per_page?: number;
}

export interface ApiResult{
  data: Array<ApiResultDataSingle>,
  data_count: number
}

export interface ApiResultDataSingle{
  id: number,
  reference: string,
  newcustomer: boolean,
  customer: string|null,
  customer_id: number,
  currency_id: number,
  delivery_country_id: number,
  total: number,
  payment: string,
  state_id: number,
  delivery_number: number,
  date: string,
  products_count: number
}

export class ApiAdminOrdersSearch extends _rootApi{
  static request(params:ApiParams, func_success?: (v:ApiResult) => void, func_error?: () => void):void{
    this._request({
      url: '/orders/search',
      params: params as unknown as {[reference:string]:string},
      func_success: (r:ApiResult) => {
        if(func_success) func_success(r);
      },
      func_error: () => {
        if(func_error) func_error();
      }
    });
  }
}