import { _rootApi } from "../../../web/global/Api/_root";

interface ApiParams{
    email: string,
    password: string
}

export interface ApiResult{
    result?: string,
    errors?: {[index:string]:Array<string>},
    message?: string
}

export class ApiAdminLogin extends _rootApi{
    static request(params:ApiParams, func_success: (result:ApiResult) => void, func_error?: (result:ApiResult) => void):void{
        this._request({
            url: '/login',
            params: params as unknown as {[index:string]:string},
            func_success: func_success,
            func_error: func_error
        });
    }
}