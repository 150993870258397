import { ApiParams } from "@/admin/global/Api/CustomersSearch";
import { _rootApi } from "../../../web/global/Api/_root";
import { saveAs } from 'file-saver';

export class ApiAdminOrdersExport extends _rootApi{
  static request(params: ApiParams, func_success?: (v:Blob) => void, func_error?: () => void):void{
    this._request({
      url: '/orders/export',
      params: params as unknown as {[index:string]:string},
      response_type: 'blob',
      func_success: (r:Blob) => {
        saveAs(r, 'orders.csv');
        if(func_success) func_success(r);
      },
      func_error: () => {
        if(func_error) func_error();
      }
    });
  }
}