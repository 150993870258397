import iziToast from "izitoast";

export class Notify{
    static success(title:string, content?:string):void{
        iziToast.success({
            title: title,
            message: content ? content : ''
        });
    }
    static error(title:string, content?: string):void{
        iziToast.error({
            title: title,
            message: content ? content : ''
        });
    }
}